<div kendoWindowContainer></div>
<mat-tab-group dynamicHeight animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
  <!-- general information -->
  <mat-tab label="General">
    <div fxLayout="column" fxLayoutAlign="space-evenly start" fxLayoutGap="20px" class="tab-element layout-column">
      <div fxFlex="auto">
        <div>Environment: {{ env }}</div>
        <div>Start Path: {{ startPath }}</div>
        <div>Current Language: {{ currentLanguage }}</div>
      </div>
      <div fxFlex="auto" [innerHTML]="dataServiceInfo"></div>
      <div fxFlex="auto">
        <mat-card style="width: 300px">
          <mat-card-header>
            <mat-card-title>{{ 'l10n_greeting' | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content style="position: relative">
            <p ngxUiLoaderBlurred [blur]="1" [loaderId]="'loader-01'">
              The Shiba Inu is the smallest of the six original and distinct
              spitz breeds of dog from Japan. A small, agile dog that copes very
              well with mountainous terrain, the Shiba Inu was originally bred
              for hunting.
            </p>
            <ngx-ui-loader [loaderId]="'loader-01'" [fgsType]="spinnerType.rectangleBounce" [fgsSize]="40"
              [fgsColor]="'white'" [overlayColor]="'rgba(50,50,50,.5)'" [pbColor]="'white'"></ngx-ui-loader>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxFlex="auto">
        <ng-template ngFor let-language [ngForOf]="languages">
          <span *ngIf="language.code.indexOf(currentLanguage) < 0" style="margin-right: 10px">
            <a [routerLink]="" (click)="onChangeLanguage(language.route)">{{
              language.label
              }}</a>
          </span>
          <span *ngIf="language.code.indexOf(currentLanguage) >= 0">{{ language.label }}
          </span>
        </ng-template>
      </div>
      <div fxFlex="auto">
        <input type="text" [(ngModel)]="fetchText" />
        <button (click)="onFetchResource()">Fetch Resource</button>
        <button (click)="onDataServiceTest()">Data Service Test</button>
        <ul *ngIf="fetchedResources.length > 0">
          <!-- <li *ngFor="let res of fetchedResources">
                  {{ res['DisplayName'] }} ({{ res['Manager'] ? res['Manager'].DisplayName : null }})
                </li> -->
          <li *ngFor="let res of fetchedResources">
            {{ res | extraValue: 'DisplayName' }} ({{
            res | extraValue: 'Manager:DisplayName'
            }})
          </li>
        </ul>
      </div>
      <div fxFlex="auto">
        <button (click)="onLogout()">Logout</button>
      </div>
      <div fxFlex="auto">
        <app-identity-link [identity]="{
            ObjectID: '354a7023-bc4a-4f19-99ff-5c27afdcf709'
          }"></app-identity-link>
      </div>
    </div>
  </mat-tab>
  <!-- components -->
  <mat-tab label="Components">
    <div fxLayout="row wrap">
      <!-- <div fxFlex="20" style="position: relative">
        <app-state-card
          [query]="'/Person'"
          [mainText]="'{0}'"
          [title]="'Total Users'"
          [iconText]="'person'"
        ></app-state-card>
      </div>
      <div fxFlex="20">
        <app-action-card
          [config]="{
            title: 'User',
            description:
              'Portal User Object, can be interpreted as Identity or Account'
          }"
        ></app-action-card>
      </div> -->
      <!-- <div fxFlex="40">
        <app-resource-table [config]="{ query: '/Person' }"></app-resource-table>
      </div>
      <div fxFlex="40">
        <app-resource-chart></app-resource-chart>
      </div> -->
    </div>
    <!-- <div style="margin-top: 20px">
      <app-tree-view
        #orgStructure
        [name]="'testTreeView'"
        [config]="{
          initQuery: '/ocgOrgUnit[DisplayName=\'de person\']',
          childrenQuery: '/ocgOrgUnit[ocgParentRef=\'%ParentID%\']',
          partialTree: true
        }"
      ></app-tree-view>
    </div> -->
    <div style="margin-top: 20px">
      <!-- <app-resource-table [config]="scrollTableConfig"></app-resource-table> -->
      <!-- <app-tree-list
        [config]="tlConfig"
        [adjustRootApiData]="adjustChildrenData"
        [adjustChildrenApiData]="adjustChildrenData"
      ></app-tree-list> -->
      <!-- <kendo-treelist
        [data]="rootResources | async"
        [fetchChildren]="fetchChildren"
        [hasChildren]="hasChildren"
        kendoTreeListExpandable
        [height]="410"
        [sortable]="true"
        (dataStateChange)="dataStateChange($event)"
      >
        <kendo-treelist-column
          [expandable]="true"
          field="name"
          title="Name"
          [width]="250"
        >
          <ng-template
            kendoTreeListHeaderTemplate
            let-column
            let-columnIndex="columnIndex"
          >
            {{ column.field }}({{ columnIndex }})
          </ng-template>
        </kendo-treelist-column>
        <kendo-treelist-column field="title" title="Title" [width]="180">
          <ng-template
            kendoTreeListHeaderTemplate
            let-column
            let-columnIndex="columnIndex"
          >
            {{ column.field }}({{ columnIndex }})
          </ng-template>
        </kendo-treelist-column>
        <kendo-treelist-column field="phone" title="Phone" [width]="180">
          <ng-template
            kendoTreeListHeaderTemplate
            let-column
            let-columnIndex="columnIndex"
          >
            {{ column.field }}({{ columnIndex }})
          </ng-template>
        </kendo-treelist-column>
      </kendo-treelist> -->
      <!-- <app-conflicts-resolver
        [attestationInfo]="crInfo"
        [contentHeight]="800"
        mode="attestation"
      ></app-conflicts-resolver> -->
      <h2>Here Custom IPG</h2>
      <!-- <app-custom-conflict-resolver [attestationInfo]="crInfo" [contentHeight]="800"
        mode="attestation"></app-custom-conflict-resolver> -->

    </div>
  </mat-tab>
  <!-- dynamically loading component -->
  <mat-tab label="Dynamic Component">
    <ng-container appDynamicContainer="dc1"></ng-container>
    <button (click)="onLoadCustomComponent()">Load Custom Component</button>
    <button style="background-color: red; color: white;" (click)="onLoadCustomResolverComponent()">Load Custom Resolver
      Component</button>
  </mat-tab>
  <!-- drag & drop -->
  <mat-tab label="Drag & Drop">
    <div style="position: absolute; right: 0; top: 20px; z-index: 100">
      <app-edit-menu [direction]="'column'" (edit)="onGridsterEdit()" (add)="onGridsterAdd()"
        (cancel)="onGridsterCancel()" (save)="onGridsterSave()"></app-edit-menu>
    </div>
    <div style="height: 800px">
      <gridster [options]="gdOptions" style="background: transparent">
        <gridster-item [item]="item" *ngFor="let item of gdItems">
          <div style="position: absolute; right: 5px; top: 5px; z-index: 10">
            <button mat-mini-fab *ngIf="gdOptions.draggable.enabled === true" (click)="onGridsterDelete($event, item)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <div style="position: absolute; right: 65px; top: 5px; z-index: 10">
            <button mat-mini-fab *ngIf="gdOptions.draggable.enabled === true" (click)="onGridsterConfig($event, item)">
              <mat-icon>settings</mat-icon>
            </button>
          </div>
          <ng-container [appDynamicContainer]="item.name"></ng-container>
        </gridster-item>
      </gridster>
    </div>
  </mat-tab>
  <!-- attribute editors -->
  <mat-tab label="Editors">
    <form (ngSubmit)="onSubmit(f, txtAccountName)" #f="ngForm">
      <div fxLayout="column" fxLayoutGap="10px" style="margin: 20px">
        <div>
          <app-editor-text #txtAccountName #editor name="txtAccountName"
            [ngModel]="editorResource | extraValue: 'AccountName'" [(config)]="configAccountName"></app-editor-text>
        </div>
        <div>
          <app-editor-boolean #editor name="chkRegister" [ngModel]="editorResource | extraValue: 'Register'"
            [config]="{ showDescription: true }"></app-editor-boolean>
        </div>
        <div>
          <app-editor-boolean #editor name="chkScope" [ngModel]="editorResource | extraValue: 'ocgObjectScope'"
            [config]="{
              attributeName: 'ocgObjectScope',
              showDescription: true,
              customValue: true,
              trueValue: '1',
              falseValue: '0'
            }"></app-editor-boolean>
        </div>
        <div>
          <app-editor-select #editor name="cmbEmployeeType" [ngModel]="editorResource | extraValue: 'EmployeeType'"
            [config]="{
              showDescription: true,
              required: true,
              dataMode: 'config',
              configKey: 'EmployeeType'
            }"></app-editor-select>
        </div>
        <div>
          <app-editor-select #editor name="cmbCountry" [ngModel]="editorResource | extraValue: 'Country'"
            [config]="configCountry" (valueChange)="onCountryChange($event)"></app-editor-select>
        </div>
        <div>
          <app-editor-select #editor name="cmbCity" [ngModel]="editorResource | extraValue: 'City'"
            [config]="configCity"></app-editor-select>
        </div>
        <div>
          <app-editor-date #editor name="dtpStartDate"
            [ngModel]="editorResource | extraValue: 'EmployeeStartDate'"></app-editor-date>
        </div>
        <div>
          <app-editor-identity #editor name="ipdIdentity" [ngModel]="editorResource | extraValue: 'Manager'" [config]="{
              isMultivalue: true,
              queryNormalSearch:
                '/Person[starts-with(DisplayName,\'%SearchText%\')]',
              allowEmptySearch: true,
              queryEmptySearch: '/Person'
            }" (valueChange)="onIdentityChange($event)"></app-editor-identity>
        </div>
        <div style="margin-top: 20px">
          <button mat-stroked-button color="primary" type="submit">
            Submit
          </button>
        </div>
      </div>
    </form>
  </mat-tab>
  <!-- xpath builder -->
  <mat-tab label="XPath Builder">
    <app-xpath-builder #xpathBuilder></app-xpath-builder>
    <button (click)="onGetXPathQuery(xpathBuilder)">Get Query</button>
  </mat-tab>
  <!-- object history -->
  <mat-tab label="Object History">
    <div style="height: 100vh; overflow-y: auto">
      <button (click)="onRefreshHistory(objectHistory)">Refresh History</button>
      <button (click)="onExpandAllHistory(objectHistory)">
        Expand all History
      </button>
      <button (click)="onCollapseAllHistory(objectHistory)">
        Collapse all History
      </button>
      <button (click)="onGotoTimeMachine()">Goto time-machine</button>
      <app-object-history #objectHistory [targetId]="'d0b44a74-c0ab-4aaa-9a1d-c7c54ea55b09'"></app-object-history>
    </div>
  </mat-tab>
  <!-- graph -->
  <mat-tab label="Graph">
    <!-- <div>
      <ngx-graph [nodes]="graphNodes" [links]="graphLinks">
        <ng-template #defsTemplate>
          <svg:marker
            id="arrow"
            viewBox="0 -5 10 10"
            refX="8"
            refY="0"
            markerWidth="4"
            markerHeight="4"
            orient="auto"
          >
            <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
          </svg:marker>
        </ng-template>

        <ng-template #nodeTemplate let-node>
          <svg:g
            class="node"
            [matTooltip]="node.id"
            (click)="onGraphNodeClick(node)"
          >
            <svg:circle
              *ngIf="node.selected"
              cx="25"
              cy="25"
              r="25"
              stroke="lightblue"
              stroke-width="2px"
              fill="none"
            ></svg:circle>
            <svg
              [attr.width]="node.dimension.width"
              [attr.height]="node.dimension.height"
            >
              <image
                x="5"
                y="5"
                [attr.width]="node.dimension.width - 10"
                [attr.height]="node.dimension.height - 10"
                [attr.href]="node.link"
              ></image>
            </svg>
          </svg:g>
        </ng-template>

        <ng-template #linkTemplate let-link>
          <svg:g class="edge" (click)="onGraphLinkClick(link)">
            <svg:path
              class="line"
              stroke-width="2"
              marker-end="url(#arrow)"
            ></svg:path>
            <svg:text class="edge-label" text-anchor="middle">
              <textPath
                class="text-path"
                [attr.href]="'#' + link.id"
                [style.dominant-baseline]="link.dominantBaseline"
                startOffset="50%"
              >
                {{ link.label }}
              </textPath>
            </svg:text>
          </svg:g>
        </ng-template>
      </ngx-graph>
    </div> -->
    <div>
      <app-event-graph #eventGraph [eventId]="'db805aab-702e-4c0e-85a7-f39ede9b4124'"
        [enableContextView]="false"></app-event-graph>
    </div>
    <button (click)="onRefreshEventGraph(eventGraph)">Refresh Graph</button>
    <button (click)="onLoadEventGrph(eventGraph)">Load Graph</button>
    <!-- <div style="margin-top: 20px">
      <app-tags-explorer></app-tags-explorer>
    </div> -->
    <div style="margin-top: 20px">
      <app-object-explorer [query]="oeQuery" [linkedAttributes]="oeLinkedAttributes"
        [travelQuery]="oeTravelQuery"></app-object-explorer>
    </div>
  </mat-tab>
  <mat-tab label="SignalR">
    <div style="margin: 20px">
      <mat-form-field class="example-full-width">
        <mat-label>Message to send</mat-label>
        <input matInput [(ngModel)]="messageToSend" />
      </mat-form-field>
      <button mat-stroked-button style="margin-bottom: 20px; margin-left: 20px" (click)="onSendToSignalR()">
        Send
      </button>
      <div>Listening SignalR messages from: {{ signalRServerAddress }}</div>
      <div>SignalR context connection ID: {{ signalRConnectionId }}</div>
      <div *ngIf="valueMessages.length > 0" style="margin-top: 20px">
        <li *ngFor="let message of valueMessages" style="margin-bottom: 10px">
          Message: {{ message }}
        </li>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>