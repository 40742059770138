import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from 'src/app/core/modules/core.module';

import { CustomRoutingModule } from './custom-routing.module';

import { SwapService } from '../core/services/swap.service';
import { BroadcastEvent } from '../core/models/dataContract.model';
import { TransService } from '../core/models/translation.model';
import { CustomService } from './services/custom.service';

import { CustomisationDemoComponent } from './components/customisation-demo/customisation-demo.component';
import { CustomConflictResolverComponent } from './components/custom-conflict-resolver/custom-conflict-resolver.component';

@NgModule({
  declarations: [CustomisationDemoComponent, CustomConflictResolverComponent],
  imports: [CommonModule, CustomRoutingModule, CoreModule],
  providers: [CustomService],
  exports: [CustomConflictResolverComponent]
})
export class CustomModule {
  constructor(private swap: SwapService, private translate: TransService) {
    this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
      if (event) {
        switch (event.name) {
          case 'refresh-language':
            this.translate.use(event.parameter);
            break;
          default:
            break;
        }
      }
    });
  }
}
