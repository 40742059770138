
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AttestationCheckElement, Resource } from 'src/app/core/models/dataContract.model';
import { CustomComponent } from 'src/app/core/models/dynamicEditor.interface';
import { ResourceService } from 'src/app/core/services/resource.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { CustomAttestationCheckElement, CustomAttestationInfo, CustomAttestationItem } from '../../custom.model';

@Component({
  selector: 'app-custom-conflict-resolver',
  templateUrl: './custom-conflict-resolver.component.html',
  styleUrls: ['./custom-conflict-resolver.component.scss']
})
export class CustomConflictResolverComponent extends CustomComponent implements OnInit {

  private arrResolves: Array<any>;

  private colorApprove = 'green';
  private colorEscalate = 'darkkhaki';
  private colorReject = 'coral';
  private colorNormal = 'gray';

  private iconApprove = 'done';
  private iconEscalate = 'support';
  private iconReject = 'bolt';

  public conflictsTitle = '';

  public commentForAllObject = '';

  public showSummarycomment = false;




  private buildDecision() {

    this.arrResolves = this.conflicts.items.map((c: CustomAttestationItem) => {
      return {
        parent: c.parent,
        childs: c.childs
          ? c.childs.map((x: CustomAttestationCheckElement) => {
            return {
              id: x.objectid,
              type: x.objecttype,
              decision: x.decision,
              comment: x.comment,
            };
          })
          : []
      };
    });

    //this.attestationInfo.decisions = this.arrResolves;
    this.componentValue = this.attestationInfo;

    if (this.canSubmit()) {
      this.swap.broadcast({ name: 'enable-popup-submit' });
    } else {
      this.swap.broadcast({ name: 'disable-popup-submit' });
    }

  }


  private canSubmit() {
    let hasValidDecision = false;
    if (!this.arrResolves) {
      return false;
    }

    this.arrResolves.forEach((item) => {
      const hasValidDecisionObjet = item.childs.some((child: any) => {
        return child.decision !== undefined && child.decision !== "";
      });

      // Store the result in the object
      hasValidDecision = hasValidDecisionObjet;
    });
    return hasValidDecision
  }


  private prepareAttastationData(value: Array<any>) {

    const parsedValue = value.reduce((acc, current) => {
      const parentKey = current.parent.objectid;

      let parentEntry = acc.find((item: { parent: { objectid: string; }; }) => item.parent.objectid === parentKey);
      if (!parentEntry) {
        // If not found, create a new entry for this parent
        parentEntry = {
          parent: current.parent,
          childs: [],
        };
        acc.push(parentEntry);
      }

      parentEntry.childs.push(current.child);

      return acc;
    }, []);

    this.attestationInfo = {
      items: parsedValue,
    };
  }

  @Input()
  attestationInfo: CustomAttestationInfo;

  @Input()
  mode: 'resolver' | 'attestation' | 'info' = 'attestation';

  @Input()
  contentHeight = 320;

  @Input()
  showDetail = true;

  @Input()
  infoText = '';

  @Input()
  get resolves() {
    return this.arrResolves;
  }
  set resolves(value: Array<any>) {
    this.arrResolves = value;
    this.resolvesChange.emit(this.arrResolves);
  }
  @Output()
  resolvesChange = new EventEmitter();

  conflicts: CustomAttestationInfo;

  selectedDecision: any = '';

  summaryDecision: 'approve' | 'escalate' | 'reject';

  summaryComment: string;

  infoConfirmed = false;

  getConflictColor(c: any, d?: string) {
    if (c.existNoMore) {
      return this.colorApprove;
    }
    const v = c.decision ?? d;
    switch (v) {
      case 'approve':
        return this.colorApprove;
      case 'escalate':
        return this.colorEscalate;
      case 'normal':
        return this.colorNormal;
      default:
        return this.colorReject;
    }
  }

  getConflictIcon(c: any, d?: string) {
    if (c.existNoMore) {
      return this.iconApprove;
    }
    const v = c.decision ?? d;
    switch (v) {
      case 'approve':
        return this.iconApprove;
      case 'escalate':
        return this.iconEscalate;
      default:
        return this.iconReject;
    }
  }

  onSummaryAction(): void {
    this.componentValue = this.attestationInfo;

    if (!this.selectedDecision && !this.summaryComment) {
      return;
    }

    if (this.conflicts) {
      this.conflicts.items.forEach((item: CustomAttestationItem) => {
        item.childs.forEach((c) => {
          c.decision = this.selectedDecision;
          c.comment = this.summaryComment
        })
      })
    }

    this.swap.broadcast({ name: 'enable-popup-submit' });

  }


  conflictColor = 'coral';
  conflictIcon = 'bolt';

  oeQuery: string;
  oeTravelQuery = `/*[ocgParentRef='%id%']`;
  oeLinkedAttributes = [
    'DisplayName',
    'ocgdirectrolerefs',
    'ocgParentRef',
    'ocgResultantObjectRefs',
  ];

  oeHeight = 300;


  constructor(private injector: Injector, private utils: UtilsService,
    private resource: ResourceService) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.data) {
      const data = JSON.parse(this.data);
      if (data) {
        if (data.mode) {
          this.mode = data.mode;
        }
        if (data.contentHeight) {
          this.contentHeight = data.contentHeight;
        }
        if (data.infoText) {
          this.infoText = data.infoText;
        }
      }
    }

    this.swap.broadcast({ name: 'disable-popup-submit' });

    if (this.componentValue) {
      const componentValue: Array<any> = JSON.parse(this.componentValue);
      if (componentValue) {
        this.prepareAttastationData(componentValue);
      }
    }

    if (this.attestationInfo) {
      this.conflicts = {
        items: this.attestationInfo.items,
        title: this.attestationInfo.title
      }

      this.conflicts.items.forEach((c: CustomAttestationItem, i: number) => {
        c.showdetail = this.showDetail;
      });

    }

  }

  onShowRoleExplorer(r: AttestationCheckElement, t: Resource) {
    r.showexplorer = !r.showexplorer;

    if (r.showexplorer) {
      const tid = this.utils.ExtraValue(t, 'objectID');
      const rid = this.utils.ExtraValue(r, 'objectID');
      this.oeQuery = `/*[ObjectID='${rid}' or ObjectID='${tid}' or (objectType='ocgOrgUnit' and ocgDirectRoleRefs='${rid}')]`;
    }
  }


  onResolve() {
    this.buildDecision();
  }

  onAttest(i?: number, value?: any, parentIndex?: number) {

    let allChildsValid = false;

    if (this.conflicts.items.length > 0 && this.conflicts.items[parentIndex]?.childs) {
      const child = this.conflicts.items[parentIndex]?.childs[i];

      if (child) {
        child.decision = value;
      } else {
        console.error('Child not found at index:', i);
      }
    }

    this.conflicts.items.forEach((item) => {
      allChildsValid = item.childs.every((c) =>
        ['approve', 'escalate', 'reject'].includes(c.decision)
      );

      if (allChildsValid) {
        this.swap.broadcast({ name: 'enable-popup-submit' });
      }
    });

  }


  onInfoConfirmation() {
    if (this.canSubmit()) {
      this.swap.broadcast({ name: 'enable-popup-submit' });
    } else {
      this.swap.broadcast({ name: 'disable-popup-submit' });
    }
  }

  onExpandAll() {
    this.conflicts.items.forEach((c: CustomAttestationItem) => {
      c.showdetail = true;
    });
  }

  onCollapseAll() {
    this.conflicts.items.forEach((c: CustomAttestationItem) => {
      c.showdetail = false;
    });
  }

  onResetAllObjects() {
    this.selectedDecision = '';
    this.summaryComment = '';
    this.conflicts.items.forEach((items) => {
      items.childs.forEach((c) => {
        c.decision = null;
        c.comment = null;
      })
    });

    this.swap.broadcast({ name: 'disable-popup-submit' });
  }

}