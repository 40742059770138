import { Injectable } from '@angular/core';
import { ComponentItem } from 'src/app/core/models/componentContract.model';
import { CustomisationDemoComponent } from '../components/customisation-demo/customisation-demo.component';
import { CustomConflictResolverComponent } from '../components/custom-conflict-resolver/custom-conflict-resolver.component';

@Injectable({
  providedIn: 'root',
})
export class CustomService {
  constructor() { }

  public getCustomComponent(name: string): ComponentItem {
    if (!name) {
      return null;
    }
    switch (name.toLowerCase()) {
      case 'customdemo':
        return { component: CustomisationDemoComponent, data: {} };
      case 'customconflictresolver':
        return { component: CustomConflictResolverComponent, data: {} };
      default:
        return null;
    }
  }

  /** Custom version number */
  public getVersion = () => {
    return '1.0.0';
  };

  /** Object view events */
  // public onViewEditorEvent = (event: ObjectViewEvent) => {};

  // public onViewAfterInit = (event: ObjectViewEvent) => {};

  // public onViewBeforeSave = (event: ObjectViewEvent) => {};

  // public onViewAddIdentities = (param: any) => {};

  // public onViewRemoveIdentities = (param: any) => {};

  // public onViewRemoveAllIdentities = (param: any) => {};

  /** Tab view events */
  // public onTabAfterInit = (event: TabViewEvent) => {};

  /** Menu events */
  // public onBuildMenuEvent = (event: MenuEvent) => {};

  // public onOpenMenuEvent = (event: MenuEvent) => {};

  // public onMenuEvent = (event: MenuEvent) => {};

  /** Wizard events */
  // public onWizardAfterInit = (event: WizardEvent) => {};

  // public onWizardBeforeClose = (event: WizardEvent) => {};

  // public onWizardAfterClose = (event: WizardEvent) => {};

  // public onWizardEditorEvent = (event: WizardEvent) => {};

  /** Sidebar events */
  // public onSidebarEvent = (event: BroadcastEvent) => {};

  /** Card events */
  // public onCardEvent = (event: BroadcastEvent) => {};

  /** Link events */
  // public onLinkEvent = (event: BroadcastEvent) => {};
}
