<!-- example code for custom component -->
<div>
  <!-- <h4>{{ componentValue }}</h4> -->
  <h2>Hallo IPG</h2>

  <!-- normal structure view -->
  <h3>{{ 'l10n_greeting' | translate }}</h3>
  <app-tree-view [name]="'customTreeView'" [config]="{
      initQuery: '/ocgOrgUnit[not(ocgParentRef=/ocgOrgUnit)]',
      childrenQuery: '/ocgOrgUnit[ocgParentRef=\'%ParentID%\']'
    }" (selectionChange)="onSelectionChange($event)"></app-tree-view>

  <!-- partial structure view -->
  <!-- <h3 style="font-weight: 400">Located in OU structure:</h3>
  <app-tree-view
    #orgStructure
    [name]="'userStructureView'"
    [spinnerMargin]="0"
    [config]="{
      childrenQuery: '/ocgOrgUnit[ocgParentRef=\'%ParentID%\']',
      partialTree: true,
      pageSize: 20
    }"
  ></app-tree-view> -->
</div>

<!-- offboarding implementation -->
<!-- <h3 style="color: gray">Reassign group owner</h3>
<div
  *ngIf="displayContent === 'assigned'"
  fxLayout="column"
  fxLayoutAlign="center center"
  style="width: 100%"
>
  <mat-icon fxFlex="none" style="font-size: 100px; width: 100px; color: #cccccc"
    >assignment_turned_in</mat-icon
  >
  <div fxFlex="none" style="margin-top: 50px; padding-top: 50px; font-size: 18px; color: #b0b0b0">
    {{ 'Reassign process is already completed' | translate }}
  </div>
</div>
<div
  *ngIf="displayContent === 'empty'"
  fxLayout="column"
  fxLayoutAlign="center center"
  style="width: 100%"
>
  <mat-icon fxFlex="none" style="font-size: 100px; width: 100px; color: #cccccc"
    >hourglass_empty</mat-icon
  >
  <div fxFlex="none" style="margin-top: 50px; padding-top: 50px; font-size: 18px; color: #b0b0b0">
    {{ 'No need to reassign, press the button to finish the process' | translate }}
  </div>
</div>
<div *ngIf="displayContent === 'assign'" fxLayout="column" fxLayoutGap="20px" style="color: gray">
  <div *ngFor="let reassignment of reassignGroups" fxLayout="row wrap" fxLayoutAlign="start center">
    <mat-icon
      style="margin-right: 10px"
      [matTooltip]="reassignment.isDisplayedOwner ? 'Displayed owner' : 'Owner'"
      >{{ reassignment.isDisplayedOwner ? 'supervised_user_circle' : 'group' }}</mat-icon
    >
    <div fxFlex="200px">
      <app-identity-link
        [identity]="reassignment.group"
        [linkActions]="['navigate', 'sideView']"
      ></app-identity-link>
    </div>
    <mat-icon style="margin-left: 20px; margin-right: 60px">arrow_forward</mat-icon>
    <app-editor-identity
      fxFlex="260px"
      [ngModel]="reassignment.owner"
      [config]="idpConfig"
      (valueChange)="onOwnerChange()"
    ></app-editor-identity>
  </div>
</div>
<button
  *ngIf="displayContent !== 'assigned'"
  mat-stroked-button
  color="primary"
  style="float: right; margin-top: 10px; margin-right: 30px"
  [disabled]="!canReassign"
  (click)="onReassign()"
>
  Reassign
</button> -->