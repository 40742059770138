import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { EMPTY, of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { ObjectHistoryComponent } from '../core/components/object-history/object-history.component';
import { UtilsService } from '../core/services/utils.service';
import { EditorIdentityComponent } from '../core/components/editor-identity/editor-identity.component';
import { ResourcePreviewComponent } from '../core/components/activities/resource-preview/resource-preview.component';
import { ResourceService } from '../core/services/resource.service';
import { ConfigService } from '../core/services/config.service';
import { ModalType } from '../core/models/componentContract.model';
import { ModalService } from '../core/services/modal.service';
import { Resource } from '../core/models/dataContract.model';

@Component({
  selector: 'app-time-machine',
  templateUrl: './time-machine.component.html',
  styleUrls: ['./time-machine.component.scss'],
})
export class TimeMachineComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription = new Subscription();

  @ViewChild('objectHistory') history: ObjectHistoryComponent;
  @ViewChild('idpTargetResource') idpTarget: EditorIdentityComponent;

  @ViewChild('resourcePreview') resourcePreview: ResourcePreviewComponent;

  targetResource = {
    dataType: 'Reference',
    displayName: 'key_target',
    description: 'l10n_historyTargetDesc',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'TargetResource',
    value: null,
  };

  isEventHistory = {
    dataType: 'Boolean',
    displayName: 'key_type',
    description: '',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'IsEventHistory',
    value: true,
  };

  startDate = {
    dataType: 'DateTime',
    displayName: 'key_from',
    description: '',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'StartDate',
    value: null,
  };

  endDate = {
    dataType: 'DateTime',
    displayName: 'key_to',
    description: '',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'EndDate',
    value: null,
  };

  statusDate = {
    dataType: 'DateTime',
    displayName: 'l10n_data_datetime',
    description: '',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'StatusDate',
    value: null,
  };

  historyNumber = 30;

  previewMode = false;

  previewResourceReadOnly = true;

  objectIDToRestore: string;
  timeToRestore: string;

  paramsId: string = undefined;

  includeIndirectChanges = false;
  showSystemEvents = true;

  showRelationshipOption = false;

  private setRelationshipOption(type = null) {
    const targetType =
      type ?? this.utils.ExtraValue(this.targetResource.value, 'ObjectType');
    if (targetType) {
      const settings = this.config.getConfigEx(
        'objectHistory:indirectChanges:settings',
        {}
      );
      if (
        Object.keys(settings).findIndex(
          (s) => s.toLowerCase() === targetType.toLowerCase()
        ) >= 0
      ) {
        this.showRelationshipOption = true;
      } else {
        this.showRelationshipOption = false;
      }
    }
  }

  constructor(
    private route: ActivatedRoute,
    private utils: UtilsService,
    private resource: ResourceService,
    private config: ConfigService,
    private modal: ModalService
  ) {}

  ngOnInit(): void {
    this.includeIndirectChanges = this.config.getConfigEx(
      'objectHistory:indirectChanges:enabledByDefault',
      false
    );

    this.showSystemEvents = this.config.getConfigEx(
      'objectHistory:showSystemEvents',
      true
    );

    this.previewResourceReadOnly = this.config.getConfig(
      'previewResourceReadOnly',
      true
    );
  }

  ngAfterViewInit() {
    let urlParams: Params;
    this.subscription.add(
      this.route.queryParams
        .pipe(
          switchMap((params: Params) => {
            urlParams = params;
            if (params.id) {
              this.paramsId = params.id;
              return this.resource.getResourceByID(params.id, ['DisplayName']);
            } else {
              return of(null);
            }
          }),
          tap((result: Resource) => {
            if (this.history && result && urlParams) {
              if (urlParams.mode === 'resource' && urlParams.time) {
                this.history.showSystemEvents = this.showSystemEvents;
                this.history.mode = urlParams.mode;
                this.history.statusTime = urlParams.time;
                setTimeout(() => {
                  this.isEventHistory.value = false;
                  this.statusDate.value = urlParams.time;
                });
              } else {
                this.setRelationshipOption(
                  this.utils.ExtraValue(result, 'ObjectType')
                );
                this.history.includeIndirectChanges =
                  this.includeIndirectChanges;
              }
              if (this.idpTarget) {
                this.idpTarget.value = urlParams.id;
              }
              this.history.targetId = urlParams.id;
              this.history.count = this.historyNumber;
              this.history.refresh();
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.previewMode = false;
    this.subscription.unsubscribe();
  }

  onApply(delay = 0) {
    this.previewMode = false;

    const process =
      delay > 0
        ? this.modal.show(ModalType.progress, 'key_processing', '', '300px')
        : null;

    setTimeout(() => {
      if (process) {
        process.close();
      }

      const strStartTime = this.startDate.value
        ? moment(this.startDate.value)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss.SSSSSSS') + 'Z'
        : '';
      const strEndTime = this.endDate.value
        ? moment(this.endDate.value)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss.SSSSSSS') + 'Z'
        : '';
      const strStatusTime = this.statusDate.value
        ? moment(this.statusDate.value)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss.SSSSSSS') + 'Z'
        : '';

      if (this.history) {
        this.setRelationshipOption();

        this.history.targetId = this.utils.ExtraValue(
          this.targetResource.value,
          'ObjectID'
        );
        if (!this.history.targetId) {
          this.history.targetId = this.paramsId;
        }
        this.history.includeIndirectChanges = this.includeIndirectChanges;
        this.history.showSystemEvents = this.showSystemEvents;
        this.history.mode = this.isEventHistory.value ? 'event' : 'resource';
        this.history.count = this.historyNumber;
        this.history.startTime = strStartTime;
        this.history.endTime = strEndTime;
        this.history.statusTime = strStatusTime;

        this.history.refresh();
      }
    }, delay);
  }

  onRestore(data: { id: string; time: string }) {
    this.objectIDToRestore = data.id;
    this.timeToRestore = data.time;

    if (
      this.resource.customViewSetting &&
      this.resource.customViewSetting.previewEnabled === true
    ) {
      this.previewMode = true;

      this.subscription.add(
        this.resource
          .startSimulation()
          .pipe(
            tap((simulationID: string) => {
              if (simulationID && this.resourcePreview) {
                this.resourcePreview.resourceReadOnly =
                  this.previewResourceReadOnly;
                this.resourcePreview.simulationID = simulationID;
                this.resourcePreview.action = this.resource.restoreResource(
                  this.objectIDToRestore,
                  this.timeToRestore,
                  simulationID
                );
                this.resourcePreview.start();
              }
            })
          )
          .subscribe()
      );
    } else {
      const confirm = this.modal.show(
        ModalType.confirm,
        'key_confirmation',
        'l10n_confirmRestore'
      );

      this.subscription.add(
        confirm
          .afterClosed()
          .pipe(
            switchMap((confirmResult: string) => {
              if (confirmResult && confirmResult === 'yes') {
                return this.resource.restoreResource(
                  this.objectIDToRestore,
                  this.timeToRestore
                );
              } else {
                return EMPTY;
              }
            }),
            tap(() => {
              this.onApply(this.config.getConfig('intervalMiddle', 500));
            })
          )
          .subscribe()
      );
    }
  }

  onExitPreview(saveChanges: boolean) {
    if (saveChanges) {
      if (this.resourcePreview) {
        this.resourcePreview.loadingPreview = true;
      }
      this.subscription.add(
        this.resource
          .restoreResource(this.objectIDToRestore, this.timeToRestore)
          .pipe(
            tap(() => {
              this.onApply(this.config.getConfig('intervalMiddle', 500));
            })
          )
          .subscribe()
      );
    } else {
      this.onApply();
    }
  }
}
