<div *ngIf="mode === 'resolver'" [innerHTML]="infoText | translate" class="resolver-info-text"></div>
<div style="margin: 5px 15px 20px 0">

  <div fxLayout="row">
    <mat-form-field fxFlex="auto" appearance="outline" style="
          font-size: 12px;
          margin-bottom: -16px;
          margin-right: 20px;
        ">
      <mat-label>{{ 'key_reasonAndAction' | translate }}</mat-label>
      <input matInput [(ngModel)]="summaryComment" />
    </mat-form-field>

    <div fxFlex="240px">
      <mat-button-toggle-group  [disabled]="!summaryComment"  [(ngModel)]="selectedDecision" style="font-size: 0.85em; height: 42px; margin-top: 5px">
        <mat-button-toggle  value="approve">
          <div fxLayout="row" fxLayoutAlign="center center" style="height: 40px">
            <div *ngIf="selectedDecision === 'approve'"  fxFlex="none"
              style="height: 35px; margin-right: 5px">
              <span class="material-icons-outlined" style="line-height: 35px">done</span>
            </div>
            <div fxFlex="auto">{{ 'key_accept' | translate }}</div>
          </div>
        </mat-button-toggle>
        <!-- <mat-button-toggle  value="escalate">
          <div fxLayout="row" fxLayoutAlign="center center" style="height: 40px">
            <div  *ngIf="selectedDecision === 'escalate'" fxFlex="none"
              style="height: 35px; margin-right: 5px">
              <span class="material-icons-outlined" style="line-height: 35px">done</span>
            </div>
            <div fxFlex="auto">
              {{ 'key_escalate' | translate }}
            </div>
          </div>
        </mat-button-toggle> -->
        <mat-button-toggle  value="reject">
          <div fxLayout="row" fxLayoutAlign="center center" style="height: 40px">
            <div  *ngIf="selectedDecision === 'reject'" fxFlex="none"
              style="height: 35px; margin-right: 5px">
              <span class="material-icons-outlined" style="line-height: 35px">done</span>
            </div>
            <div fxFlex="auto">{{ 'key_reject' | translate }}</div>
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div
          fxFlex="none"
          fxLayout="row"
          fxLayoutGap="8px"
        >
          <button mat-flat-button color="primary" (click)="onSummaryAction()">
            {{ 'key_execonall' | translate }}
          </button>
          <div fxFlex="auto"></div>
          <button mat-stroked-button color="primary" (click)="onResetAllObjects()">
            {{ 'key_reset' | translate }}
          </button>
        </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between">
    <div *ngIf="mode === 'attestation'" fxFlex="auto" style="line-height: 45px; font-size: 1.15em"
      [innerHtml]="conflicts.title"></div> 
  </div>

</div>
<div class="full-size-relative" [style.height.px]="contentHeight" style="overflow-y: auto; padding-right: 10px">

  <div *ngFor="let conflictItem of conflicts.items; let parentIndex = index" style="margin-bottom: 20px; border: 1px solid rgba(0, 0, 0, 0.12); padding: 10px; border-radius: 8px;">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="none" class="navigation-arrow" (click)="conflictItem.showdetail = !conflictItem.showdetail"
        style="cursor: pointer">
        <span class="material-icons-outlined">{{
          conflictItem.showdetail ? 'expand_more' : 'navigate_next'
          }}</span>
      </div>
      <div fxFlex="auto">
        <div (click)="conflictItem.showdetail = !conflictItem.showdetail" style="cursor: pointer; font-weight: 500;">
          {{ conflictItem.parent | extraValue: 'displayName' }}
        </div>

        <div *ngFor="let infos  of conflictItem.parent.additionalinfos" style="font-size: 14px; margin-top: 5px;">
          {{infos}}
        </div>
        
      </div>
    </div>
    <div *ngIf="conflictItem.showdetail">

      <div *ngFor="let childItem of conflictItem.childs; let i = index" class="sod-field">
        <div>
          <div fxFlex="50" class="conflict-item" [style.border-color]="getConflictColor(childItem, 'normal')">
            <div fxLayout="row">
              <div fxFlex="auto">
                <div style="cursor: pointer; font-weight: 500; margin-bottom: 8px;" (click)="onShowRoleExplorer(childItem, conflictItem.parent)">
                  {{ childItem | extraValue: 'displayName' }}
                </div>
                <div *ngFor="let child of childItem.additionalinfos " style="font-size: 14px; margin-top: 5px;">
                  {{child}}
                </div>
              </div>
              <div fxFlex="none" [matTooltip]="
                        (conflictItem.isdirect ? 'key_directRole' : 'key_inheritedRole')
                          | translate
                      ">
                <span class="material-icons-outlined role-lock">{{
                  conflictItem.isdirect ? 'lock_open' : 'lock'
                  }}</span>
              </div>
            </div>
          </div>
          <div *ngIf="mode === 'attestation'" fxFlex="none" class="attestation-option-line"
            [style.border-color]="getConflictColor(childItem, 'normal')"></div>
          <div *ngIf="mode === 'attestation'" fxFlex="50" class="attestation-option"
            [style.border-color]="getConflictColor(childItem, 'normal')">
            <div fxLayout="row">
              <mat-form-field fxFlex="auto" appearance="outline" style="
                    font-size: 12px;
                    margin-bottom: -16px;
                    margin-right: 20px;
                  ">
                <mat-label>{{ 'key_reasonAndAction' | translate }}</mat-label>
                <input matInput [(ngModel)]="childItem.comment" (change)="onAttest()" />
              </mat-form-field>

              <div fxFlex="240px">
                <mat-button-toggle-group [(ngModel)]="childItem.decision" [disabled]="!childItem.comment"
                  (change)="onAttest(i,  $event.value, parentIndex)" style="font-size: 0.85em; height: 42px; margin-top: 5px">
                  <mat-button-toggle *ngIf="childItem.approve" value="approve">
                    <div fxLayout="row" fxLayoutAlign="center center" style="height: 40px">
                      <div *ngIf="childItem.decision === 'approve'" fxFlex="none"
                        style="height: 35px; margin-right: 5px">
                        <span class="material-icons-outlined" style="line-height: 35px">done</span>
                      </div>
                      <div fxFlex="auto">{{ 'key_accept' | translate }}</div>
                    </div>
                  </mat-button-toggle>
                  <mat-button-toggle *ngIf="childItem.escalate" value="escalate">
                    <div fxLayout="row" fxLayoutAlign="center center" style="height: 40px">
                      <div *ngIf="childItem.decision === 'escalate'" fxFlex="none"
                        style="height: 35px; margin-right: 5px">
                        <span class="material-icons-outlined" style="line-height: 35px">done</span>
                      </div>
                      <div fxFlex="auto">
                        {{ 'key_escalate' | translate }}
                      </div>
                    </div>
                  </mat-button-toggle>
                  <mat-button-toggle *ngIf="childItem.reject" value="reject">
                    <div fxLayout="row" fxLayoutAlign="center center" style="height: 40px">
                      <div *ngIf="childItem.decision === 'reject'" fxFlex="none"
                        style="height: 35px; margin-right: 5px">
                        <span class="material-icons-outlined" style="line-height: 35px">done</span>
                      </div>
                      <div fxFlex="auto">{{ 'key_reject' | translate }}</div>
                    </div>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="childItem.showexplorer" fxLayout="row">
          <div fxFlex="50px"></div>
          <div fxFlex="auto" class="object-explorer-line-left"></div>
          <div fxFlex="60%" class="object-explorer">
            <app-object-explorer #objExplorer [title]="' '" [query]="oeQuery" [travelQuery]="oeTravelQuery"
              [linkedAttributes]="oeLinkedAttributes" [height]="oeHeight" [showPlaceHolder]="false"
              [highlightCategories]="['ocgrole', 'person']"></app-object-explorer>
          </div>
          <div fxFlex="auto"></div>
          <div fxFlex="50px"></div>
        </div>
      </div>

    </div>
  </div>